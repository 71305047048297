// Invite.tsx
import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../store';
import { fetchInviteData, InviteStatus, InviteData } from '../slices/inviteSlice.ts';

const COPY_TIMEOUT = 1250;

type InviteParams = {
  code: string;
};

const Invite: React.FC = () => {
  const { code } = useParams<InviteParams>();
  const dispatch: AppDispatch = useDispatch();

  const { data: inviteData, error, status } = useSelector((state: RootState) => state.invite);
  const [isCodeCopied, setIsCodeCopied] = useState(false);

  useEffect(() => {
    if (code) {
      dispatch(fetchInviteData(code));
    }
  }, [code, dispatch]);

  const handleCopyCode = useCallback(() => {
    if (code) {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(code).then(() => {
          setIsCodeCopied(true);
          setTimeout(() => setIsCodeCopied(false), COPY_TIMEOUT);
        });
      } else {
        // Фоллбек для старых браузеров
        const textarea = document.createElement('textarea');
        textarea.value = code;
        document.body.appendChild(textarea);
        textarea.style.position = 'fixed';
        textarea.style.opacity = '0';
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);

        setIsCodeCopied(true);
        setTimeout(() => setIsCodeCopied(false), COPY_TIMEOUT);
      }
    }
  }, [code]);

  if (error) {
    return (
      <main className="main">
        <div className="section">
          <div className="container --l">
            <div className="section-inner">
              <h2 className="section-heading"><i>W</i>ish it</h2>
              <p>{error}</p>
            </div>
          </div>
        </div>
      </main>
    );
  }

  if (status === InviteStatus.Loading || !inviteData) {
    return (
      <main className="main">
        <div className="section">
          <div className="container --l">
            <div className="section-inner">
              <h2 className="section-heading"><i>W</i>ish it</h2>
              <p>Loading...</p>
            </div>
          </div>
        </div>
      </main>
    );
  }

  const { username, avatarURL } = inviteData;

  return (
    <main className="main">
      <section className="section">
        <div className="container --l">
          <div className="section-inner">
            <h2 className="section-heading">
              <i>Y</i>ou're invited
            </h2>
            {avatarURL ? (
              <div className="team-image image cover rounded">
                <img src={avatarURL} alt={username} />
              </div>
            ) : (
              <div className="team-image rounded team-image_empty">
                <span className="no-username">
                  <i>{username.charAt(0)}</i>
                </span>
              </div>
            )}
            <p className="team-by">
              <span>by</span> <span id="team-name">{username === 'wishit' ? 'Our Team' : username}</span>
            </p>
            <div className="team-code__container">
              <p className="team-code__head">Use code</p>
              <div className="team-code__board" id="team-code__board" onClick={handleCopyCode}>
                <div className={`team-code__board-code ${isCodeCopied ? 'animated' : ''}`} id="team-code__board-code">
                  <span className="team-code__code" id="team-code__code">{code}</span>
                </div>
                <div className={`team-code__board-message ${isCodeCopied ? 'animated' : ''}`} id="team-code__board-message">
                  <span>Code copied!</span>
                </div>
              </div>
            </div>
            <a className="image contain image-appstore image-appstore_bigger" href="#">
              <img src="/assets/img/appstore_dark.svg" alt="Download on AppStore" />
            </a>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Invite;
