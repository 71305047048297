// slices/handleSlice.ts

import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axiosInstance from '../utils/axiosInstance.ts';

export enum HandleStatus {
  Idle = 'idle',
  Loading = 'loading',
  Succeeded = 'succeeded',
  Failed = 'failed',
}

export enum UsernameAvailability {
  Available = 'AVAILABLE',
  Unavailable = 'UNAVAILABLE',
  Invalid = 'INVALID',
}

export interface HandleData {
  availability: UsernameAvailability;
  message: string | null;
}

interface HandleState {
  data: HandleData | null;
  error: string | null;
  status: HandleStatus;
}

const initialState: HandleState = {
  data: null,
  error: null,
  status: HandleStatus.Idle,
};

// Thunk для проверки имени пользователя
export const checkUsername = createAsyncThunk<HandleData, string, { rejectValue: string }>(
  'handle/checkUsername',
  async (username, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/api/check-username?username=${username}`);
      if (response.data.availability === UsernameAvailability.Invalid) {
        return rejectWithValue('Invalid username format');
      }
      return response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue('An error occurred while checking the username');
    }
  }
);

// Slice
const handleSlice = createSlice({
  name: 'handle',
  initialState,
  reducers: {
    resetHandleState: (state) => {
      state.data = null;
      state.error = null;
      state.status = HandleStatus.Idle;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(checkUsername.pending, (state) => {
        state.status = HandleStatus.Loading;
        state.error = null;
      })
      .addCase(checkUsername.fulfilled, (state, action: PayloadAction<HandleData>) => {
        state.status = HandleStatus.Succeeded;
        state.data = action.payload;
      })
      .addCase(checkUsername.rejected, (state, action) => {
        state.status = HandleStatus.Failed;
        state.error = action.payload || 'An error occurred';
      });
  },
});

// Экспорт экшена сброса состояния
export const { resetHandleState } = handleSlice.actions;

export default handleSlice.reducer;
