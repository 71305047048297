// components/Header.js
import React from 'react';
import { Link } from 'react-router-dom';

function Header() {
  return (
    <header className="header">
      <div className="container --l">
        <Link to="/" className="header-logo logo">
          <i>W</i>ish.it
        </Link>
      </div>
    </header>
  );
}

export default Header;
