// App.tsx
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import Header from './components/Header.js';
import Footer from './components/Footer.js';
import Main from './components/Main.tsx';
import DownloadApp from './components/DownloadApp.tsx';
import Terms from './components/Terms.js';
import Privacy from './components/Privacy.js';
import Invite from './components/Invite.tsx';
import ScrollToTop from './components/ScrollToTop.tsx';

function App() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Wish It</title>
      </Helmet>
      <Header />
      <div className="underlay"></div>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/downloadApp" element={<DownloadApp />} />
        <Route path="/terms-and-conditions" element={<Terms />} />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/r/:code" element={<Invite />} />
      </Routes>
      <Footer />
    </HelmetProvider>
  );
}

export default App;
