// components/Privacy.js
import React from 'react';

function Privacy() {
  return (
    <main class="main">
      <div class="section section-legal">
        <div class="container --s">
          <div class="section-inner">
            <h1>PRIVACY POLICY</h1>
            <p>Last updated August 30, 2024</p><br />
            <p>This Privacy Notice for Wish It B.V. ("<b>we</b>," "<b>us</b>," or "<b>our</b>"), describes how and why we might access, collect, store, use, and/or share ("process") your personal information when you use our services ("Services"), including when you:</p>
            <p>Visit our website at <a href="https://wishit.live">https://wishit.live</a>, or any website of ours that links to this Privacy Notice</p>
            <p>Download and use our mobile application (Wish It), or any other application of ours that links to this Privacy Notice</p>
            <p>Engage with us in other related ways, including any sales, marketing, or events</p><br />
            <p>Questions or concerns? Reading this Privacy Notice will help you understand your privacy rights and choices. We are responsible for making decisions about how your personal information is processed. If you do not agree with our policies and practices, please do not use our Services. If you still have any questions or concerns, please contact us at support@wishit.live.</p>
            <h2>SUMMARY OF KEY POINTS</h2>
            <p><em>This summary provides key points from our Privacy Notice, but you can find out more details about any of these topics by clicking the link following each key point or by using our <a href="#toc">table of contents</a> below to find the section you are looking for.</em></p>
            <p><b>What personal information do we process?</b> When you visit, use, or navigate our Services, we may process personal information depending on how you interact with us and the Services, the choices you make, and the products and features you use. Learn more about <a href="#personalinfo">personal information you disclose to us</a>.</p>
            <p><b>Do we process any sensitive personal information?</b> Some of the information may be considered "special" or "sensitive" in certain jurisdictions, for example your racial or ethnic origins, sexual orientation, and religious beliefs. We may process sensitive personal information when necessary with your consent or as otherwise permitted by applicable law. Learn more about <a href="#sensitiveinfo">sensitive information we process</a>.</p>
            <p><b>Do we collect any information from third parties?</b> We may collect information from public databases, marketing partners, social media platforms, and other outside sources. Learn more about <a href="#othersources">information collected from other sources</a>.</p>
            <p><b>How do we process your information?</b> We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so. Learn more about <a href="#infouse">how we process your information</a>.</p>
            <p><b>In what situations and with which types of parties do we share personal information?</b> We may share information in specific situations and with specific categories of third parties. Learn more about <a href="#whoshare">when and with whom we share your personal information</a>.</p>
            <p><b>How do we keep your information safe?</b> We have adequate organizational and technical processes and procedures in place to protect your personal information. However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Learn more about <a href="#infosafe">how we keep your information safe</a>.</p>
            <p><b>What are your rights?</b> Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information. Learn more about <a href="#privacyrights">your privacy rights</a>.</p>
            <p><b>How do you exercise your rights?</b> The easiest way to exercise your rights is by submitting a <a href="https://app.termly.io/notify/d52157b2-4ca6-442b-82e8-769b6aedbea6">data subject access request</a>, or by contacting us. We will consider and act upon any request in accordance with applicable data protection laws.</p>
            <p>Want to learn more about what we do with any information we collect? <a href="#toc">Review the Privacy Notice in full</a>.</p>
            <h2 id="toc">TABLE OF CONTENTS</h2>
            <ol>
              <li> <a href="#infocollect">WHAT INFORMATION DO WE COLLECT?</a></li>
              <li> <a href="#infouse">HOW DO WE PROCESS YOUR INFORMATION?</a></li>
              <li> <a href="#legaluse">WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL INFORMATION?</a></li>
              <li> <a href="#whoshare">WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</a></li>
              <li> <a href="#cookies">DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</a></li>
              <li> <a href="#ai">DO WE OFFER ARTIFICIAL INTELLIGENCE-BASED PRODUCTS?</a></li>
              <li> <a href="#inforetain">HOW LONG DO WE KEEP YOUR INFORMATION?</a></li>
              <li> <a href="#infosafe">HOW DO WE KEEP YOUR INFORMATION SAFE?</a></li>
              <li> <a href="#privacyrights">WHAT ARE YOUR PRIVACY RIGHTS?</a></li>
              <li> <a href="#DNT">CONTROLS FOR DO-NOT-TRACK FEATURES</a></li>
              <li> <a href="#uslaws">DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</a></li>
              <li> <a href="#otherlaws">DO OTHER REGIONS HAVE SPECIFIC PRIVACY RIGHTS?</a></li>
              <li> <a href="#policyupdated">DO WE MAKE UPDATES TO THIS NOTICE?</a></li>
              <li> <a href="#contact">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a></li>
              <li> <a href="#request">HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</a></li>
            </ol>
            <h2 id="infocollect">1. WHAT INFORMATION DO WE COLLECT?</h2>
            <h3>Personal information you disclose to us</h3>
            <p><em><b>In Short:</b> We collect personal information that you provide to us.</em></p>
            <p>We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.</p>
            <p><b>Personal Information Provided by You.</b> The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following:</p>
            <ul>
              <li>names</li>
              <li>phone numbers</li>
              <li>email addresses</li>
              <li>usernames</li>
              <li>passwords</li>
              <li>billing addresses</li>
              <li>debit/credit card numbers</li>
              <li>contact or authentication data</li>
            </ul>
            <p><b>Sensitive Information.</b> When necessary, with your consent or as otherwise permitted by applicable law, we process the following categories of sensitive information:</p>
            <ul>
              <li>financial data</li>
            </ul>
            <p><b>Payment Data.</b> We may collect data necessary to process your payment if you choose to make purchases, such as your payment instrument number, and the security code associated with your payment instrument. All payment data is handled and stored by Yoomoney. You may find their privacy notice link(s) here: https://yoomoney.eu/policy/.</p>
            <p><b>Application Data.</b> If you use our application(s), we also may collect the following information if you choose to provide us with access or permission:</p>
            <ul>
              <li>Geolocation Information. We may request access or permission to track location-based information from your mobile device, either continuously or while you are using our mobile application(s), to provide certain location-based services. If you wish to change our access or permissions, you may do so in your device's settings.</li>
              <li>Mobile Device Access. We may request access or permission to certain features from your mobile device, including your mobile device's camera, microphone, contacts, reminders, social media accounts, calendar, and other features. If you wish to change our access or permissions, you may do so in your device's settings.</li>
              <li>Mobile Device Data. We automatically collect device information (such as your mobile device ID, model, and manufacturer), operating system, version information and system configuration information, device and application identification numbers, browser type and version, hardware model Internet service provider and/or mobile carrier, and Internet Protocol (IP) address (or proxy server). If you are using our application(s), we may also collect information about the phone network associated with your mobile device, your mobile device’s operating system or platform, the type of mobile device you use, your mobile device’s unique device ID, and information about the features of our application(s) you accessed.</li>
              <li>Push Notifications. We may request to send you push notifications regarding your account or certain features of the application(s). If you wish to opt out from receiving these types of communications, you may turn them off in your device's settings.</li>
            </ul>
            <p>This information is primarily needed to maintain the security and operation of our application(s), for troubleshooting, and for our internal analytics and reporting purposes.</p>
            <p>All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.</p>
            <h3>Information automatically collected</h3>
            <p><em><b>In Short:</b> Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our Services.</em></p>
            <p>We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services, and other technical information. This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.</p>
            <p>Like many businesses, we also collect information through cookies and similar technologies.</p>
            <p>The information we collect includes:</p>
            <ul>
              <li>Log and Usage Data. Log and usage data is service-related, diagnostic, usage, and performance information our servers automatically collect when you access or use our Services and which we record in log files. Depending on how you interact with us, this log data may include your IP address, device information, browser type, and settings and information about your activity in the Services (such as the date/time stamps associated with your usage, pages and files viewed, searches, and other actions you take such as which features you use), device event information (such as system activity, error reports (sometimes called "crash dumps"), and hardware settings).</li>
              <li>Device Data. We collect device data such as information about your computer, phone, tablet, or other device you use to access the Services. Depending on the device used, this device data may include information such as your IP address (or proxy server), device and application identification numbers, location, browser type, hardware model, Internet service provider and/or mobile carrier, operating system, and system configuration information.</li>
              <li>Location Data. We collect location data such as information about your device's location, which can be either precise or imprecise. How much information we collect depends on the type and settings of the device you use to access the Services. For example, we may use GPS and other technologies to collect geolocation data that tells us your current location (based on your IP address). You can opt out of allowing us to collect this information either by refusing access to the information or by disabling your Location setting on your device. However, if you choose to opt out, you may not be able to use certain aspects of the Services.</li>
            </ul>
            <h3>Google API</h3>
            <p>Our use of information received from Google APIs will adhere to <a href="https://developers.google.com/terms/api-services-user-data-policy">Google API Services User Data Policy</a>, including the <a href="https://developers.google.com/terms/api-services-user-data-policy#limited-use">Limited Use requirements</a>.</p>
            <h3>Information collected from other sources</h3>
            <p><em><b>In Short:</b> We may collect limited data from public databases, marketing partners, and other outside sources.</em></p>
            <p>In order to enhance our ability to provide relevant marketing, offers, and services to you and update our records, we may obtain information about you from other sources, such as public databases, joint marketing partners, affiliate programs, data providers, and from other third parties. This information includes mailing addresses, job titles, email addresses, phone numbers, intent data (or user behavior data), Internet Protocol (IP) addresses, social media profiles, social media URLs, and custom profiles, for purposes of targeted advertising and event promotion.</p>
            <h2 id="infouse">2. HOW DO WE PROCESS YOUR INFORMATION?</h2>
            <p><em><b>In Short:</b> We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.</em></p>
            <p><b>We process your personal information for a variety of reasons, depending on how you interact with our Services, including:</b></p>
            <ul>
              <li><b>To facilitate account creation and authentication and otherwise manage user accounts.</b> We may process your information so you can create and log in to your account, as well as keep your account in working order.</li>
              <li><b>To deliver and facilitate delivery of services to the user.</b> We may process your information to provide you with the requested service.</li>
              <li><b>To respond to user inquiries/offer support to users.</b> We may process your information to respond to your inquiries and solve any potential issues you might have with the requested service.</li>
              <li><b>To fulfill and manage your orders.</b> We may process your information to fulfill and manage your orders, payments, returns, and exchanges made through the Services.</li>
              <li><b>To enable user-to-user communications.</b> We may process your information if you choose to use any of our offerings that allow for communication with another user.</li>
              <li><b>To request feedback.</b> We may process your information when necessary to request feedback and to contact you about your use of our Services.</li>
              <li><b>To send you marketing and promotional communications.</b> We may process the personal information you send to us for our marketing purposes, if this is in accordance with your marketing preferences. You can opt out of our marketing emails at any time. For more information, see "<a href="#privacyrights">WHAT ARE YOUR PRIVACY RIGHTS?</a>" below.</li>
              <li><b>To deliver targeted advertising to you.</b> We may process your information to develop and display personalized content and advertising tailored to your interests, location, and more.</li>
              <li><b>To protect our Services.</b> We may process your information as part of our efforts to keep our Services safe and secure, including fraud monitoring and prevention.</li>
              <li><b>To identify usage trends.</b> We may process information about how you use our Services to better understand how they are being used so we can improve them.</li>
              <li><b>To determine the effectiveness of our marketing and promotional campaigns.</b> We may process your information to better understand how to provide marketing and promotional campaigns that are most relevant to you.</li>
              <li><b>To save or protect an individual's vital interest.</b> We may process your information when necessary to save or protect an individual’s vital interest, such as to prevent harm.</li>
            </ul>
            <h2 id="legaluse">3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?</h2>
            <p><em><b>In Short:</b> We only process your personal information when we believe it is necessary and we have a valid legal reason (i.e., legal basis) to do so under applicable law, like with your consent, to comply with laws, to provide you with services to enter into or fulfill our contractual obligations, to protect your rights, or to fulfill our legitimate business interests.</em></p>
            <h3><em style={{ textDecoration: 'underline' }}>If you are located in the EU or UK, this section applies to you.</em></h3>
            <p>The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid legal bases we rely on in order to process your personal information. As such, we may rely on the following legal bases to process your personal information:</p>
            <ul>
              <li><b>Consent.</b> We may process your information if you have given us permission (i.e., consent) to use your personal information for a specific purpose. You can withdraw your consent at any time. Learn more about <a href="#withdrawconsent">withdrawing your consent</a>.</li>
              <li><b>Performance of a Contract.</b> We may process your personal information when we believe it is necessary to fulfill our contractual obligations to you, including providing our Services or at your request prior to entering into a contract with you.</li>
              <li><b>Legitimate Interests.</b> We may process your information when we believe it is reasonably necessary to achieve our legitimate business interests and those interests do not outweigh your interests and fundamental rights and freedoms. For example, we may process your personal information for some of the purposes described in order to:
                <ul>
                  <li>Send users information about special offers and discounts on our products and services</li>
                  <li>Develop and display personalized and relevant advertising content for our users</li>
                  <li>Analyze how our Services are used so we can improve them to engage and retain users</li>
                  <li>Support our marketing activities</li>
                  <li>Diagnose problems and/or prevent fraudulent activities</li>
                  <li>Understand how our users use our products and services so we can improve user experience</li>
                </ul>
              </li>
              <li><b>Legal Obligations.</b> We may process your information where we believe it is necessary for compliance with our legal obligations, such as to cooperate with a law enforcement body or regulatory agency, exercise or defend our legal rights, or disclose your information as evidence in litigation in which we are involved.</li>
              <li><b>Vital Interests.</b> We may process your information where we believe it is necessary to protect your vital interests or the vital interests of a third party, such as situations involving potential threats to the safety of any person.</li>
            </ul>
            <h3><em style={{ textDecoration: 'underline' }}>If you are located in Canada, this section applies to you.</em></h3>
            <p>We may process your information if you have given us specific permission (i.e., express consent) to use your personal information for a specific purpose, or in situations where your permission can be inferred (i.e., implied consent). You can <a href="#withdrawconsent">withdraw your consent</a> at any time.</p>
            <p>In some exceptional cases, we may be legally permitted under applicable law to process your information without your consent, including, for example:</p>
            <ul>
              <li>If collection is clearly in the interests of an individual and consent cannot be obtained in a timely way</li>
              <li>For investigations and fraud detection and prevention</li>
              <li>For business transactions provided certain conditions are met</li>
              <li>If it is contained in a witness statement and the collection is necessary to assess, process, or settle an insurance claim</li>
              <li>For identifying injured, ill, or deceased persons and communicating with next of kin</li>
              <li>If we have reasonable grounds to believe an individual has been, is, or may be victim of financial abuse</li>
              <li>If it is reasonable to expect collection and use with consent would compromise the availability or the accuracy of the information and the collection is reasonable for purposes related to investigating a breach of an agreement or a contravention of the laws of Canada or a province</li>
              <li>If disclosure is required to comply with a subpoena, warrant, court order, or rules of the court relating to the production of records</li>
              <li>If it was produced by an individual in the course of their employment, business, or profession and the collection is consistent with the purposes for which the information was produced</li>
              <li>If the collection is solely for journalistic, artistic, or literary purposes</li>
              <li>If the information is publicly available and is specified by the regulations</li>
            </ul>
            <h2 id="whoshare">4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h2>
            <p><em><b>In Short:</b> We may share information in specific situations described in this section and/or with the following categories of third parties.</em></p>
            <p><b>Vendors, Consultants, and Other Third-Party Service Providers.</b> We may share your data with third-party vendors, service providers, contractors, or agents ("<b>third parties</b>") who perform services for us or on our behalf and require access to such information to do that work.</p>
            <p>The categories of third parties we may share personal information with are as follows:</p>
            <ul>
              <li>Ad Networks</li>
              <li>Affiliate Marketing Programs</li>
              <li>AI Platforms</li>
              <li>Cloud Computing Services</li>
              <li>Communication & Collaboration Tools</li>
              <li>Data Analytics Services</li>
              <li>Data Storage Service Providers</li>
              <li>Finance & Accounting Tools</li>
              <li>Payment Processors</li>
              <li>Performance Monitoring Tools</li>
              <li>Product Engineering & Design Tools</li>
              <li>Retargeting Platforms</li>
              <li>Sales & Marketing Tools</li>
              <li>Social Networks</li>
              <li>Testing Tools</li>
              <li>User Account Registration & Authentication Services</li>
              <li>Website Hosting Service Providers</li>
            </ul>
            <p>We also may need to share your personal information in the following situations:</p>
            <ul>
              <li><b>Business Transfers.</b> We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</li>
              <li><b>When we use Google Maps Platform APIs.</b> We may share your information with certain Google Maps Platform APIs (e.g., Google Maps API, Places API). Google Maps uses GPS, Wi-Fi, and cell towers to estimate your location. GPS is accurate to about 20 meters, while Wi-Fi and cell towers help improve accuracy when GPS signals are weak, like indoors. This data helps Google Maps provide directions, but it is not always perfectly precise. We obtain and store on your device ("cache") your location. You may revoke your consent anytime by contacting us at the contact details provided at the end of this document.</li>
              <li><b>Affiliates.</b> We may share your information with our affiliates, in which case we will require those affiliates to honor this Privacy Notice. Affiliates include our parent company and any subsidiaries, joint venture partners, or other companies that we control or that are under common control with us.</li>
              <li><b>Business Partners.</b> We may share your information with our business partners to offer you certain products, services, or promotions.</li>
              <li><b>Other Users.</b> When you share personal information (for example, by posting comments, contributions, or other content to the Services) or otherwise interact with public areas of the Services, such personal information may be viewed by all users and may be publicly made available outside the Services in perpetuity. Similarly, other users will be able to view descriptions of your activity, communicate with you within our Services, and view your profile.</li>
              <li><b>Offer Wall.</b> Our application(s) may display a third-party hosted "offer wall." Such an offer wall allows third-party advertisers to offer virtual currency, gifts, or other items to users in return for the acceptance and completion of an advertisement offer. Such an offer wall may appear in our application(s) and be displayed to you based on certain data, such as your geographic area or demographic information. When you click on an offer wall, you will be brought to an external website belonging to other persons and will leave our application(s). A unique identifier, such as your user ID, will be shared with the offer wall provider in order to prevent fraud and properly credit your account with the relevant reward.</li>
            </ul>
            <h2 id="cookies">5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h2>
            <p><em><b>In Short:</b> We may use cookies and other tracking technologies to collect and store your information.</em></p>
            <p>We may use cookies and similar tracking technologies (like web beacons and pixels) to gather information when you interact with our Services. Some online tracking technologies help us maintain the security of our Services and your account, prevent crashes, fix bugs, save your preferences, and assist with basic site functions.</p>
            <p>We also permit third parties and service providers to use online tracking technologies on our Services for analytics and advertising, including to help manage and display advertisements, to tailor advertisements to your interests, or to send abandoned shopping cart reminders (depending on your communication preferences). The third parties and service providers use their technology to provide advertising about products and services tailored to your interests which may appear either on our Services or on other websites.</p>
            <p>To the extent these online tracking technologies are deemed to be a "sale"/"sharing" (which includes targeted advertising, as defined under the applicable laws) under applicable US state laws, you can opt out of these online tracking technologies by submitting a request as described below under section "<a href="#uslaws">DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</a>"</p>
            <p>Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice.</p>
            <h3>Google Analytics</h3>
            <p>We may share your information with Google Analytics to track and analyze the use of the Services. The Google Analytics Advertising Features that we may use include: Remarketing with Google Analytics, Google Display Network Impressions Reporting and Google Analytics Demographics and Interests Reporting. To opt out of being tracked by Google Analytics across the Services, visit <a href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a>. You can opt out of Google Analytics Advertising Features through <a href="https://adssettings.google.com/">Ads Settings</a> and Ad Settings for mobile apps. Other opt out means include <a href="http://optout.networkadvertising.org/">http://optout.networkadvertising.org/</a> and <a href="http://www.networkadvertising.org/mobile-choice">http://www.networkadvertising.org/mobile-choice</a>. For more information on the privacy practices of Google, please visit the <a href="https://policies.google.com/privacy">Google Privacy & Terms page</a>.</p>
            <h2 id="ai">6. DO WE OFFER ARTIFICIAL INTELLIGENCE-BASED PRODUCTS?</h2>
            <p><em><b>In Short:</b> We offer products, features, or tools powered by artificial intelligence, machine learning, or similar technologies.</em></p>
            <p>As part of our Services, we offer products, features, or tools powered by artificial intelligence, machine learning, or similar technologies (collectively, "AI Products"). These tools are designed to enhance your experience and provide you with innovative solutions. The terms in this Privacy Notice govern your use of the AI Products within our Services.</p>
            <h3>Use of AI Technologies</h3>
            <p>We provide the AI Products through third-party service providers ("AI Service Providers"), including OpenAI, Amazon Web Services (AWS) AI, Google Cloud AI, Microsoft Azure AI, NVIDIA AI and Oracle AI. As outlined in this Privacy Notice, your input, output, and personal information will be shared with and processed by these AI Service Providers to enable your use of our AI Products for purposes outlined in "<a href="#legalbases">WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL INFORMATION?</a>" You must not use the AI Products in any way that violates the terms or policies of any AI Service Provider.</p>
            <h3>Our AI Products</h3>
            <p>Our AI Products are designed for the following functions:</p>
            <ul>
              <li>AI search</li>
              <li>AI applications</li>
              <li>AI development</li>
              <li>AI insights</li>
              <li>Machine learning models</li>
              <li>Natural language processing</li>
              <li>AI document generation</li>
            </ul>
            <h3>How We Process Your Data Using AI</h3>
            <p>All personal information processed using our AI Products is handled in line with our Privacy Notice and our agreement with third parties. This ensures high security and safeguards your personal information throughout the process, giving you peace of mind about your data's safety.</p>
            <h2 id="inforetain">7. HOW LONG DO WE KEEP YOUR INFORMATION?</h2>
            <p><em><b>In Short:</b> We keep your information for as long as necessary to fulfill the purposes outlined in this Privacy Notice unless otherwise required by law.</em></p>
            <p>We will only keep your personal information for as long as it is necessary for the purposes set out in this Privacy Notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than twelve (12) months past the termination of the user's account.</p>
            <p>When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.</p>
            <h2 id="infosafe">8. HOW DO WE KEEP YOUR INFORMATION SAFE?</h2>
            <p><em><b>In Short:</b> We aim to protect your personal information through a system of organizational and technical security measures.</em></p>
            <p>We have implemented appropriate and reasonable technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.</p>
            <h2 id="privacyrights">9. WHAT ARE YOUR PRIVACY RIGHTS?</h2>
            <p><em><b>In Short:</b> Depending on your state of residence in the US or in some regions, such as the European Economic Area (EEA), United Kingdom (UK), Switzerland, and Canada, you have rights that allow you greater access to and control over your personal information. You may review, change, or terminate your account at any time, depending on your country, province, or state of residence.</em></p>
            <p>In some regions (like the EEA, UK, Switzerland, and Canada), you have certain rights under applicable data protection laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal information; (iv) if applicable, to data portability; and (v) not to be subject to automated decision-making. In certain circumstances, you may also have the right to object to the processing of your personal information. You can make such a request by contacting us by using the contact details provided in the section "<a href="#contact">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a>" below.</p>
            <p>We will consider and act upon any request in accordance with applicable data protection laws.</p>
            <p>If you are located in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the right to complain to your <a href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm">Member State data protection authority</a> or <a href="https://ico.org.uk/make-a-complaint/data-protection-complaints/data-protection-complaints/">UK data protection authority</a>.</p>
            <p>If you are located in Switzerland, you may contact the <a href="https://www.edoeb.admin.ch/edoeb/en/home.html">Federal Data Protection and Information Commissioner</a>.</p>
            <p><b style={{ textDecoration: 'underline' }}>Withdrawing your consent:</b> If we are relying on your consent to process your personal information, which may be express and/or implied consent depending on the applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details provided in the section "<a href="#contact">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a>" below or updating your preferences.</p>
            <p>However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.</p>
            <p><b style={{ textDecoration: 'underline' }}>Opting out of marketing and promotional communications:</b> You can unsubscribe from our marketing and promotional communications at any time by clicking on the unsubscribe link in the emails that we send, replying "STOP" or "UNSUBSCRIBE" to the SMS messages that we send, or by contacting us using the details provided in the section "<a href="#contact">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a>" below. You will then be removed from the marketing lists. However, we may still communicate with you — for example, to send you service-related messages that are necessary for the administration and use of your account, to respond to service requests, or for other non-marketing purposes.</p>
            <h3>Account Information</h3>
            <p>If you would at any time like to review or change the information in your account or terminate your account, you can:</p>
            <ul>
              <li>Log in to your account settings and update your user account.</li>
              <li>Contact us using the contact information provided.</li>
            </ul>
            <p>Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal requirements.</p>
            <p><b style={{ textDecoration: 'underline' }}>Cookies and similar technologies:</b> Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services of our Services.</p>
            <p>If you have questions or comments about your privacy rights, you may email us at support@wishit.live.</p>
            <h2 id="DNT">10. CONTROLS FOR DO-NOT-TRACK FEATURES</h2>
            <p>Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage, no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this Privacy Notice.</p>
            <p>California law requires us to let you know how we respond to web browser DNT signals. Because there currently is not an industry or legal standard for recognizing or honoring DNT signals, we do not respond to them at this time.</p>
            <h2 id="uslaws">11. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h2>
            <p><em><b>In Short:</b> If you are a resident of California, Colorado, Connecticut, Delaware, Florida, Indiana, Iowa, Kentucky, Montana, New Hampshire, New Jersey, Oregon, Tennessee, Texas, Utah, or Virginia, you may have the right to request access to and receive details about the personal information we maintain about you and how we have processed it, correct inaccuracies, get a copy of, or delete your personal information. You may also have the right to withdraw your consent to our processing of your personal information. These rights may be limited in some circumstances by applicable law. More information is provided below.</em></p>
            <p>Categories of Personal Information We Collect</p>
            <p>We have collected the following categories of personal information in the past twelve (12) months:</p>
            <table>
              <thead>
                <tr>
                  <th>Category</th>
                  <th>Examples</th>
                  <th>Collected</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>A. Identifiers</td>
                  <td>Contact details, such as real name, alias, postal address, telephone or mobile contact number, unique personal identifier, online identifier, Internet Protocol address, email address, and account name</td>
                  <td> </td>
                </tr>
                <tr>
                  <td>B. Personal information as defined in the California Customer Records statute</td>
                  <td>Name, contact information, education, employment, employment history, and financial information</td>
                  <td> </td>
                </tr>
                <tr>
                  <td>C. Protected classification characteristics under state or federal law</td>
                  <td>Gender, age, date of birth, race and ethnicity, national origin, marital status, and other demographic data</td>
                  <td> </td>
                </tr>
                <tr>
                  <td>D. Commercial information</td>
                  <td>Transaction information, purchase history, financial details, and payment information</td>
                  <td> </td>
                </tr>
                <tr>
                  <td>E. Biometric information</td>
                  <td>Fingerprints and voiceprints</td>
                  <td> </td>
                </tr>
                <tr>
                  <td>F. Internet or other similar network activity</td>
                  <td>Browsing history, search history, online behavior, interest data, and interactions with our and other websites, applications, systems, and advertisements</td>
                  <td> </td>
                </tr>
                <tr>
                  <td>G. Geolocation data</td>
                  <td>Device location</td>
                  <td> </td>
                </tr>
                <tr>
                  <td>H. Audio, electronic, sensory, or similar information</td>
                  <td>Images and audio, video or call recordings created in connection with our business activities</td>
                  <td> </td>
                </tr>
                <tr>
                  <td>I. Professional or employment-related information</td>
                  <td>Business contact details in order to provide you our Services at a business level or job title, work history, and professional qualifications if you apply for a job with us</td>
                  <td> </td>
                </tr>
                <tr>
                  <td>J. Education Information</td>
                  <td>Student records and directory information</td>
                  <td> </td>
                </tr>
                <tr>
                  <td>K. Inferences drawn from collected personal information</td>
                  <td>Inferences drawn from any of the collected personal information listed above to create a profile or summary about, for example, an individual’s preferences and characteristics</td>
                  <td>YES</td>
                </tr>
                <tr>
                  <td>L. Sensitive personal Information</td>
                  <td>Account login information, biometric data, contents of email or text messages, debit or credit card numbers and precise geolocation </td>
                  <td>YES</td>
                </tr>
              </tbody>
            </table>
            <p>We only collect sensitive personal information, as defined by applicable privacy laws or the purposes allowed by law or with your consent. Sensitive personal information may be used, or disclosed to a service provider or contractor, for additional, specified purposes. You may have the right to limit the use or disclosure of your sensitive personal information.</p>
            <p>We may also collect other personal information outside of these categories through instances where you interact with us in person, online, or by phone or mail in the context of:</p>
            <ul>
              <li>Receiving help through our customer support channels;</li>
              <li>Participation in customer surveys or contests; and</li>
              <li>Facilitation in the delivery of our Services and to respond to your inquiries.</li>
            </ul>
            <p>We will use and retain the collected personal information as needed to provide the Services or for:</p>
            <ul>
              <li>Category A - As long as the user has an account with us</li>
              <li>Category B - As long as the user has an account with us</li>
              <li>Category C - As long as the user has an account with us</li>
              <li>Category D - As long as the user has an account with us</li>
              <li>Category E - As long as the user has an account with us</li>
              <li>Category F - As long as the user has an account with us</li>
              <li>Category G - As long as the user has an account with us</li>
              <li>Category H - As long as the user has an account with us</li>
              <li>Category I - As long as the user has an account with us</li>
              <li>Category J - As long as the user has an account with us</li>
              <li>Category K - As long as the user has an account with us</li>
              <li>Category L - As long as the user has an account with us</li>
            </ul>
            <h3>Sources of Personal Information</h3>
            <p>Learn more about the sources of personal information we collect in "<a href="#infocollect">WHAT INFORMATION DO WE COLLECT?</a>"</p>
            <h3>How We Use and Share Personal Information</h3>
            <p>Learn about how we use your personal information in the section, "<a href="#infouse">HOW DO WE PROCESS YOUR INFORMATION?</a>"</p>
            <p>We collect and share your personal information through:</p>
            <ul>
              <li>Targeting cookies/Marketing cookies</li>
              <li>Social media cookies</li>
              <li>Click redirects: We use services such as Branch.io and Google Analytics to track redirects and clicks for performance analysis and marketing purposes.</li>
              <li>Social media plugins: Facebook, Twitter, and Instagram. We use social media features, such as a "Like" button, and widgets, such as a "Share" button, in our Services. Such features may process your Internet Protocol (IP) address and track which page you are visiting on our website. We may place a cookie to enable the feature to work correctly. If you are logged in on a certain social media platform and you interact with a widget or button belonging to that social media platform, this information may be recorded to your profile of such social media platform. To avoid this, you should log out from that social media platform before accessing or using the Services. Social media features and widgets may be hosted by a third party or hosted directly on our Services. Your interactions with these features are governed by the privacy notices of the companies that provide them. By clicking on one of these buttons, you agree to the use of this plugin and consequently the transfer of personal information to the corresponding social media service. We have no control over the essence and extent of these transmitted data or their additional processing.</li>
            </ul>
            <h3>Will your information be shared with anyone else?</h3>
            <p>We may disclose your personal information with our service providers pursuant to a written contract between us and each service provider. Learn more about how we disclose personal information to in the section, "<a href="#whoshare">WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</a>"</p>
            <p>We may use your personal information for our own business purposes, such as for undertaking internal research for technological development and demonstration. This is not considered to be "selling" of your personal information.</p>
            <p>We have disclosed the following categories of personal information to third parties for a business or commercial purpose in the preceding twelve (12) months:</p>
            <ul>
              <li>Category A. Identifiers</li>
              <li>Category B. Personal information as defined in the California Customer Records law</li>
              <li>Category C. Characteristics of protected classifications under state or federal law</li>
              <li>Category D. Commercial information</li>
              <li>Category E. Biometric information</li>
              <li>Category F. Internet or other electronic network activity information</li>
              <li>Category G. Geolocation data</li>
              <li>Category H. Audio, electronic, visual, and similar information</li>
              <li>Category I. Professional or employment-related information</li>
              <li>Category J. Education information</li>
              <li>Category L. Sensitive personal information</li>
            </ul>
            <p>The categories of third parties to whom we disclosed personal information for a business or commercial purpose can be found under "<a href="#whoshare">WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</a>"</p>
            <p>We have sold or shared the following categories of personal information to third parties in the preceding twelve (12) months:</p>
            <p>The categories of third parties to whom we sold personal information are:</p>
            <ul>
              <li>Ad Networks</li>
              <li>AI Platforms</li>
              <li>Affiliate Marketing Programs</li>
              <li>Data Analytics Services</li>
              <li>Retargeting Platforms</li>
              <li>Social Networks</li>
              <li>User Account Registration & Authentication Services</li>
            </ul>
            <p>The categories of third parties to whom we shared personal information with are:</p>
            <ul>
              <li>Ad Networks</li>
              <li>Affiliate Marketing Programs</li>
              <li>AI Platforms</li>
              <li>Data Analytics Services</li>
              <li>Retargeting Platforms</li>
              <li>Social Networks</li>
              <li>User Account Registration & Authentication Services</li>
            </ul>
            <h3>Your Rights</h3>
            <p>You have rights under certain US state data protection laws. However, these rights are not absolute, and in certain cases, we may decline your request as permitted by law. These rights include:</p>
            <ul>
              <li><b>Right to know</b> whether or not we are processing your personal data</li>
              <li><b>Right to access</b> your personal data</li>
              <li><b>Right to correct</b> inaccuracies in your personal data</li>
              <li><b>Right to request</b> the deletion of your personal data</li>
              <li><b>Right to obtain a copy</b> of the personal data you previously shared with us</li>
              <li><b>Right to non-discrimination</b> for exercising your rights</li>
              <li><b>Right to opt out</b> of the processing of your personal data if it is used for targeted advertising (or sharing as defined under California’s privacy law), the sale of personal data, or profiling in furtherance of decisions that produce legal or similarly significant effects ("profiling")</li>
            </ul>
            <p>Depending upon the state where you live, you may also have the following rights:</p>
            <ul>
              <li>Right to obtain a list of the categories of third parties to which we have disclosed personal data (as permitted by applicable law, including California's and Delaware's privacy law)</li>
              <li>Right to obtain a list of specific third parties to which we have disclosed personal data (as permitted by applicable law, including Oregon’s privacy law)</li>
              <li>Right to limit use and disclosure of sensitive personal data (as permitted by applicable law, including California’s privacy law)</li>
              <li>Right to opt out of the collection of sensitive data and personal data collected through the operation of a voice or facial recognition feature (as permitted by applicable law, including Florida’s privacy law)</li>
            </ul>
            <h3>How to Exercise Your Rights</h3>
            <p>To exercise these rights, you can contact us by submitting a <a href="https://app.termly.io/notify/d52157b2-4ca6-442b-82e8-769b6aedbea6">data subject access request</a>, by emailing us at support@wishit.live, or by referring to the contact details at the bottom of this document.</p>
            <p>You can opt out from the selling of your personal information, targeted advertising, or profiling by disabling cookies in Cookie Preference Settings.</p>
            <p>Under certain US state data protection laws, you can designate an authorized agent to make a request on your behalf. We may deny a request from an authorized agent that does not submit proof that they have been validly authorized to act on your behalf in accordance with applicable laws.</p>
            <h3>Request Verification</h3>
            <p>Upon receiving your request, we will need to verify your identity to determine you are the same person about whom we have the information in our system. We will only use personal information provided in your request to verify your identity or authority to make the request. However, if we cannot verify your identity from the information already maintained by us, we may request that you provide additional information for the purposes of verifying your identity and for security or fraud-prevention purposes.</p>
            <p>If you submit the request through an authorized agent, we may need to collect additional information to verify your identity before processing your request and the agent will need to provide a written and signed permission from you to submit such request on your behalf.</p>
            <h3>Appeals</h3>
            <p>Under certain US state data protection laws, if we decline to take action regarding your request, you may appeal our decision by emailing us at support@wishit.live. We will inform you in writing of any action taken or not taken in response to the appeal, including a written explanation of the reasons for the decisions. If your appeal is denied, you may submit a complaint to your state attorney general.</p>
            <p>California "Shine The Light" Law</p>
            <p>California Civil Code Section 1798.83, also known as the "Shine The Light" law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us by using the contact details provided in the section "<a href="#contact">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a>"</p>
            <h2 id="otherlaws">12. DO OTHER REGIONS HAVE SPECIFIC PRIVACY RIGHTS?</h2>
            <p><em><b>In Short:</b> You may have additional rights based on the country you reside in.</em></p>
            <h3>Australia and New Zealand</h3>
            <p>We collect and process your personal information under the obligations and conditions set by Australia's Privacy Act 1988 and New Zealand's Privacy Act 2020 (Privacy Act).</p>
            <p>This Privacy Notice satisfies the notice requirements defined in both Privacy Acts, in particular: what personal information we collect from you, from which sources, for which purposes, and other recipients of your personal information.</p>
            <p>If you do not wish to provide the personal information necessary to fulfill their applicable purpose, it may affect our ability to provide our services, in particular:</p>
            <ul>
              <li>offer you the products or services that you want</li>
              <li>respond to or help with your requests</li>
              <li>manage your account with us</li>
              <li>confirm your identity and protect your account</li>
            </ul>
            <p>At any time, you have the right to request access to or correction of your personal information. You can make such a request by contacting us by using the contact details provided in the section "<a href="#request">HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</a>"</p>
            <p>If you believe we are unlawfully processing your personal information, you have the right to submit a complaint about a breach of the Australian Privacy Principles to the <a href="#https://www.oaic.gov.au/privacy/privacy-complaints/lodge-a-privacy-complaint-with-us">Office of the Australian Information Commissioner</a> and a breach of New Zealand's Privacy Principles to <a href="https://www.privacy.org.nz/your-rights/making-a-complaint/">the Office of New Zealand Privacy Commissioner</a>.</p>
            <h3>Republic of South Africa</h3>
            <p>At any time, you have the right to request access to or correction of your personal information. You can make such a request by contacting us by using the contact details provided in the section "<a href="#request">HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</a>"</p>
            <p>If you are unsatisfied with the manner in which we address any complaint with regard to our processing of personal information, you can contact the office of the regulator, the details of which are:</p>
            <p><a href="https://inforegulator.org.za/">The Information Regulator (South Africa)</a></p>
            <p>General enquiries: <a href="mailto:enquiries@inforegulator.org.za">enquiries@inforegulator.org.za</a></p>
            <p>Complaints (complete POPIA/PAIA form 5): <a href="mailto:PAIAComplaints@inforegulator.org.za">PAIAComplaints@inforegulator.org.za</a> & <a href="mailto:POPIAComplaints@inforegulator.org.za">POPIAComplaints@inforegulator.org.za</a></p>
            <h2 id="policyupdated">13. DO WE MAKE UPDATES TO THIS NOTICE?</h2>
            <p><em><b>In Short:</b> Yes, we will update this notice as necessary to stay compliant with relevant laws.</em></p>
            <p>We may update this Privacy Notice from time to time. The updated version will be indicated by an updated "Revised" date at the top of this Privacy Notice. If we make material changes to this Privacy Notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this Privacy Notice frequently to be informed of how we are protecting your information.</p>
            <h2 id="contact">14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h2>
            <p>If you have questions or comments about this notice, you may email us at support@wishit.live or contact us by post at:</p>
            <p>Wish It B.V. <br /> Sint Jacobsstraat 18, 1012 NC Amsterdam, Netherlands <br /> Amsterdam, Noord-Holland 1012 NC <br /> Netherlands</p>
            <h2 id="request">15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</h2>
            <p>You have the right to request access to the personal information we collect from you, details about how we have processed it, correct inaccuracies, or delete your personal information. You may also have the right to withdraw your consent to our processing of your personal information. These rights may be limited in some circumstances by applicable law. To request to review, update, or delete your personal information, please fill out and submit a <a href="https://app.termly.io/notify/d52157b2-4ca6-442b-82e8-769b6aedbea6">data subject access request</a>.</p>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Privacy;
