// slices/inviteSlice.ts
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axiosInstance from '../utils/axiosInstance.ts'; // Импортируем наш экземпляр axios

export enum InviteStatus {
  Idle = 'idle',
  Loading = 'loading',
  Succeeded = 'succeeded',
  Failed = 'failed',
}

export interface InviteData {
  username: string;
  avatarURL?: string;
}

interface InviteState {
  data: InviteData | null;
  error: string | null;
  status: InviteStatus;
}

const initialState: InviteState = {
  data: null,
  error: null,
  status: InviteStatus.Idle,
};

// Async thunk для получения данных инвайта
export const fetchInviteData = createAsyncThunk<InviteData, string, { rejectValue: string }>(
  'invite/fetchInviteData',
  async (code, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/api/invite/meta', { code });
      return response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue('Invalid invite code');
    }
  }
);

// Slice
const inviteSlice = createSlice({
  name: 'invite',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchInviteData.pending, (state) => {
        state.status = InviteStatus.Loading;
        state.error = null;
      })
      .addCase(fetchInviteData.fulfilled, (state, action: PayloadAction<InviteData>) => {
        state.status = InviteStatus.Succeeded;
        state.data = action.payload;
      })
      .addCase(fetchInviteData.rejected, (state, action) => {
        state.status = InviteStatus.Failed;
        state.error = action.payload || 'An error occurred';
      });
  },
});

export default inviteSlice.reducer;
