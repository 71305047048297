// src/components/Slider.js
import React from 'react';
import SwiperCore, { Autoplay, EffectCoverflow } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';

SwiperCore.use([Autoplay, EffectCoverflow]);

const sliderItems = [
  { title: "Bottega Veneta green leather clutch", price: "$2,500.00", image: "./assets/img/slider/sl_1.jpg" },
  { title: "Bottega Veneta Step Hoop Earrings", price: "$1,250.00", image: "./assets/img/slider/sl_2.jpg" },
  { title: "Jacquemus “Le Chiquito” tote", price: "$620.00", image: "./assets/img/slider/sl_3.jpg" },
  { title: "MARNI Jacquard Shopping Bag, Blue + Orange", price: "$1,399.00", image: "./assets/img/slider/sl_4.jpg" },
  { title: "Lapointe High Waisted Leg Pant", price: "$850.00", image: "./assets/img/slider/sl_5.jpg" },
  { title: "Tiffany & Co. Lock Bangle", price: "$2,600.00", image: "./assets/img/slider/sl_6.jpg" },
  { title: "Guerlain Tonka Imperiale", price: "$355.00", image: "./assets/img/slider/sl_7.jpg" },
  { title: "SAINT LAURENT Sunglasses", price: "$300.00", image: "./assets/img/slider/sl_8.jpg" },
  { title: "NINA RICCI Orange High Hat", price: "$1,343.00", image: "./assets/img/slider/sl_9.jpg" },
  { title: "Jacquemus Grey 'Camiteso' Oversize Coat", price: "$969.00", image: "./assets/img/slider/sl_10.jpg" },
  { title: "MARNI Striped Mohair-Blend Sweater", price: "$1,070.00", image: "./assets/img/slider/sl_11.jpg" },
  { title: "Maison Margiela Tabi mary-jane shoes", price: "$990.00", image: "./assets/img/slider/sl_12.jpg" },
  { title: "LOEWE Curve oversized acetate sunglasses", price: "$2,499.00", image: "./assets/img/slider/sl_13.jpg" },
  { title: "Bottega Veneta Tire Chelsea Boot", price: "$1,450.00", image: "./assets/img/slider/sl_14.jpg" },
];

function Slider() {
  return (
    <div className="section slider" id="slider">
      <div className="slider__mockup">
        <div className="image cover mockup">
          <img className="mockup-frame" src="./assets/img/mockup_phone.png" alt="#" />
          <div
            className="image cover mockup-inner"
            style={{ backgroundColor: 'black', height: '100%', width: '100%' }}
          >
            <img className="mockup-image" src="/assets/img/screen_slider.png" alt="#" />
          </div>
        </div>
      </div>
      <Swiper
        loop={true}
        centeredSlides={true}
        slidesPerView={2}
        autoplay={{ delay: 2250 }}
        speed={500}
        spaceBetween={64}
        effect="coverflow"
        coverflowEffect={{
          stretch: 0,
          depth: 500,
          rotate: 0,
          modifier: 0.7,
          slideShadows: false,
        }}
        breakpoints={{
          767: {
            slidesPerView: 3,
            coverflowEffect: {
              stretch: 0,
              depth: 500,
              rotate: 0,
              modifier: 0.45,
              slideShadows: false,
            },
          },
        }}
        className="swiper slider-swiper"
      >
        {sliderItems.map((item, index) => (
          <SwiperSlide key={index} className="swiper-slide slider__slide">
            <div className="slider__slide-inner">
              <div className="image cover slider__slide-image">
                <img
                  src={`./assets/img/slider/sl_${index + 1}.jpg`}
                  alt={item[0]}
                />
              </div>
              <span className="slider__slide-title">{item[0]}</span>
              <span className="slider__slide-price">{item[1]}</span>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export default Slider;
