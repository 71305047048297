// store.ts
import { configureStore } from '@reduxjs/toolkit';
import handleReducer from './slices/handleSlice.ts';
import inviteReducer from './slices/inviteSlice.ts';

export default configureStore({
  reducer: {
    handle: handleReducer,
    invite: inviteReducer,
  },
});

// Получаем тип состояния всего приложения
export type RootState = ReturnType<typeof store.getState>;
// Получаем тип диспетчера для использования в dispatch
export type AppDispatch = typeof store.dispatch;